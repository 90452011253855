<template>
  <main id="tg-main" class="tg-main tg-haslayout">
    <div class="tg-sectionspace tg-haslayout">
      <div class="container">
        <div class="row">
          <div id="tg-twocolumns" class="tg-twocolumns">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 pull-right">
              <div id="tg-content" class="tg-content">
                <div class="tg-productdetail">
                  <div class="row">
                    <!-- 左侧 -->
                    <div class="col-md-4 col-lg-4">
                      <div
                        class="row"
                        style="display: flex; flex-direction: column; justify-content: center;align-items: center;"
                      >
                        <img
                          :src="book.coverUrl"
                          alt="封面"
                          style="width: 290px;height:290px;object-fit:cover;"
                        />
                      </div>
                      <div class="row recommand">
                        <div class="recommand__title">
                          浏览此商品的顾客也同时浏览
                        </div>
                        <div class="recommand__content">
                          <sidebar type="tg-widgettrending" model="book" :limit="3" />
                        </div>
                      </div>
                    </div>

                    <!-- 右侧 -->
                    <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                      <div class="tg-productcontent">
                        <div class="booktitle">
                          {{ book.title }}
                        </div>
                        <div style="padding-bottom: 16px;border-bottom: 1px solid rgba(0,0,0,0.2);">
                          <div class="book-short-desc" style="margin-top:18px; ">
                            {{ book.desc }}
                          </div>
                        </div>

                        <div style="margin-top: 10px;display: flex;">
                          <div> 作者：{{ book.author }}</div>
                          <div style="margin-left: 50px;"> 出品出版社：{{ book.press }}</div>
                          <div style="margin-left: 50px;"> 出版时间：{{ book.publishDate }}</div>
                        </div>

                        <div class="cart-area">
                          <div class="cart-area__price-box">
                            <div class="cart-area__price-unit">
                              ￥
                            </div>
                            <div class="cart-area__price">
                              {{ book.price | money }}
                              <span class="discount">{{ book.discount }}</span>
                            </div>
                          </div>
                        </div>

                        <div
                          class="buttons"
                          style="margin-top:23px;display:flex;align-items:center"
                        >
                          <el-button type="primary" size="small" @click="AddFavorite">
                            <div style="display:flex; align-items: center;">
                              <img
                                src="/images/icons/collect.png"
                                style="width:24px;height:24px;"
                                @click="AddFavorite"
                              />
                              <span style="margin-left: 5px;font-size:16px;">收藏</span>
                            </div>
                          </el-button>
                          <el-popover placement="top" width="160" style="margin-left: 20px;">
                            <!-- 购买按钮弹窗 -->
                            <div style="text-align: right; margin: 0">
                              <template v-if="!book.jdUrl && !book.tbUrl">
                                该书暂无购买链接
                              </template>
                              <template v-else>
                                <el-button
                                  type="primary"
                                  size="mini"
                                  :disabled="!book.jdUrl"
                                  @click="
                                    () => {
                                      window.open(book.jdUrl)
                                    }
                                  "
                                  >京东购买地址</el-button
                                >
                                <el-button
                                  type="primary"
                                  size="mini"
                                  :disabled="!book.tbUrl"
                                  @click="
                                    () => {
                                      window.open(book.tbUrl)
                                    }
                                  "
                                  >淘宝购买地址</el-button
                                >
                              </template>
                            </div>
                            <!-- 购买按钮插槽 -->
                            <el-button slot="reference" plain type="primary" size="small">
                              <div style="display:flex;align-items:center;">
                                <img
                                  src="/images/icons/shopping.png"
                                  style="width:24px;height:24px;"
                                />
                                <span style="font-size:16px;margin-left:5px;">购买</span>
                              </div>
                            </el-button>
                          </el-popover>
                        </div>

                        <div class="tg-sectionhead">
                          <div>商品详情</div>
                        </div>
                        <div class="tg-description" style="display:flex;">
                          <div>
                            <span>开本：</span><span>{{ book.format }}</span>
                          </div>

                          <div style="margin-left: 50px;">
                            <span>纸张：</span><span>{{ book.material }}</span>
                          </div>
                          <div style="margin-left: 50px;">
                            <span>包装：</span><span>{{ book.paperback }}</span>
                          </div>

                          <div style="margin-left: 50px;">
                            <span>是否套装：</span><span>{{ book.isPackage }}</span>
                          </div>

                          <div style="margin-left: 50px;">
                            <span>国际标准书号ISBN：</span><span>{{ book.ISBN }}</span>
                          </div>
                        </div>

                        <div class="tg-sectionhead">
                          <div>内容简介</div>
                        </div>
                        <div class="tg-description">
                          <p v-html="book.desc"></p>
                        </div>

                        <div class="tg-sectionhead">
                          <div>作者简介</div>
                        </div>
                        <span class="tg-description">
                          <div>{{ book.author }}</div>
                          <div>{{ book.authorDesc }}</div>
                        </span>

                        <div class="tg-sectionhead">
                          <div>目录</div>
                        </div>
                        <div class="tg-description">
                          <ol>
                            <li v-for="(cate, i) in book.index" :key="i">
                              {{ cate.name }}
                            </li>
                          </ol>
                        </div>

                        <div class="tg-sectionhead" v-if="book.detail">
                          <div>图书详情</div>
                        </div>
                        <div class="tg-description">
                          <div v-html="book.detail"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { Popover } from 'element-ui'
import { SingleQuery, CommonQuery } from '../api/commonquery'
import Sidebar from '../components/Sidebar.vue'
export default {
  components: { Sidebar, [Popover.name]: Popover },
  data() {
    return {
      book: {},
      books: [],
    }
  },
  methods: {
    async getData(id) {
      let res = await SingleQuery({
        model: 'book',
        id,
      })
      this.book = res.data
      console.log(res.data)
      document.title = this.book.title
    },

    /**
     * 获取相关图书，参数待调整
     */
    async getBooks() {
      let res = await CommonQuery({
        model: 'book',
        query: {},
        limit: 3,
      })
      this.books = res.data.map((book) => {
        return {
          ...book,
          to: {
            name: 'bookDetail',
            query: {
              id: book._id,
            },
          },
        }
      })
    },

    //收藏本站
    AddFavorite() {
      const title = this.book.title
      const url = window.location
      try {
        window.external.addFavorite(url, title)
      } catch (e) {
        try {
          window.sidebar.addPanel(title, url, '')
        } catch (e) {
          alert('抱歉，您所使用的浏览器无法完成此操作。\n\n加入收藏失败，请使用Ctrl+D进行添加')
        }
      }
    },

    purchase() {
      if (!this.book.jdUrl && !this.book.tbUrl) {
        alert('该书没有购买地址')
      }

      $()
    },
  },
  created() {
    let query = this.$route.query
    if (query.id) {
      this.getData(this.$route.query.id)
      // this.getBooks()
    } else {
      console.log('无id')
    }
  },
}
</script>

<style scoped>
/* 推荐 */
.recommand {
  margin-top: 29px;
  padding: 0 19px;
}
.recommand__title {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 详情 */
.booktitle {
  font-size: 20px;
  font-weight: bold;
  font-family: '黑体';
}

/* 短介绍 */
.book-short-desc {
  margin-top: 18px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.tg-sectionhead {
  margin: 20px 0 10px 0;
  padding: 0 0 10px 0;
}

.tg-sectionhead > div {
  border-left: 5px solid #f05000;
  font-size: 16px;
  padding-left: 10px;
}

.tg-description {
  padding: 0 10px;
}

.cart-area__price-box {
  margin-top: 24px;
  background: rgba(240, 80, 0, 0.05);
  display: flex;
  align-items: flex-end;
  padding: 20px 0 19px 10px;
}

.discount {
  font-size: 14px;
}

.cart-area__price-unit {
  color: #f05000;
  font-size: 12px;
}

.cart-area__price {
  font-size: 25px;
  color: #f05000;
}
</style>
