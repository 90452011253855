<template>
<div class="tg-widget" :class="type">
  <div class="tg-widgetcontent">
    <!-- 图片列表插槽 -->
    <slot name="pic-list">
      <ul v-if="type == 'tg-widgettrending'">
        <li v-for="item in list" :key="item._id" class="book-item">
          <book style="width: 170px;" :book="item"/>
        </li>
      </ul>
    </slot>
  </div>
</div>
</template>

<script>
import { CommonQuery } from '../api/commonquery'
import Book from './Book.vue'
export default {
  components: { Book },
  name: 'Sidebar',
  props: ['name', 'type', 'model', 'linkName', 'limit'],
  data() {
    return {
      list: []
    }
  },
  methods: {
    async getData () {
      const res = await CommonQuery({
        model: this.model,
        query: {},
        limit: this.limit || 3
      })
      this.list = res.data.map((book) => {
        return {
          ...book,
          cover: book.cover + '?imageMogr2/thumbnail/220x330!',
          readed: book.readed || 0,
          to: {
            name: 'bookDetail',
            query: {
              id: book._id
            }
          }
        }
      })
    },
    onTypeClick (item) {
      this.$emit('change', item)
    }
  },
  created () {
    this.getData()
  }
}
</script>

<style scoped>
.book-item {
  padding: 10px 30px !important; 
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 2px dotted rgba(0,0,0, 0.1) !important;
}
</style>